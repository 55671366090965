import { PathTemplatesFragment as PathTemplates } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';

export const usePathTemplate = (pageType: keyof PathTemplates) => {
  const { site } = useAppContext();

  const path = site.pathTemplates[pageType];

  if (!path) {
    throw Error(`${pageType} doesn't have path defined in siteConfigs`);
  }

  return path;
};
